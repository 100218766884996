import React, { useEffect, useState } from "react";

//import lib
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";

//import api
import { getSptCat, createNewTicket } from "../../api/support";

//import validation
import validation from "./validation";

const initialFormValue = {
    categoryId: "",
    message: "",
    image: {},
};
let imageType = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'pdf', 'PDF']
const CreateTicket = () => {

    //state
    const [categoryList, setCategoryList] = useState([]);
    const [formValue, setFormValue] = useState(initialFormValue);
    const [loader, setLoader] = useState();
    const [validateError, setValidateError] = useState({});
    const { categoryId, message, image } = formValue;

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        if (!isEmpty(validateError)) {
            setValidateError({});
        }
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (!imageType.includes(files[0].type.split('/')[1])) {
            return toastAlert("error", 'Image can only be in JPEG / PNG /Jpg  formats', "updateProfile");
      
          }
          if (files[0]?.size >= 1000000) {
            return toastAlert("error", "Upload image cannot more then 1MB!", "updateProfile");
          }
        let formData = { ...formValue, ...{ [name]: files[0] } };
        setFormValue(formData);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let reqData = {
            categoryId,
            message,
            image,
        };
        let validationError = validation(reqData, "createTicket");
        if (!isEmpty(validationError)) {
            setValidateError(validationError);
            setLoader(false);
            return;
        }
        let formData = new FormData();
        formData.append("categoryId", categoryId);
        formData.append("message", message);
        formData.append("image", image);
        setLoader(true);
        try {
            const { status, loading, message, error } = await createNewTicket(
                formData
            );
            setLoader(loading);
            if (status == "success") {
                setFormValue(initialFormValue);
                toastAlert("success", message, "createTicket");
                setTimeout(() => {
                    window.location.reload(false);
                }, 1500)

            } else {
                setValidateError(error);
                toastAlert("error", message, "createTicket");
            }
        } catch (err) {
            console.log(err, 'error')
        }
    };

    const fetchCategory = async () => {
        try {
            const { status, loading, error, result } = await getSptCat();
            if (status == "success") {
                setCategoryList(result);
            }
        } catch (err) {
            console.log(err, 'error')
        }
    };

    useEffect(() => {
        fetchCategory();
    }, []);

    return (
        <>
            <div className="dash_wrapper_flex_left">
                <div className="inner_subtitle_wrap inner_subtitle_wrap_flex">
                    <h3>Submit a Request</h3>
                </div>
                <form className="assets_form">
                    <div className="mb-3">
                        <label for="Tickets" className="form-label">
                            Ticket For
                        </label>
                        <select value={categoryId}
                            name="categoryId" onChange={handleChange} placeholder="Select Category" className="form-select primary_asset_select">
                            <option>Select Category</option>
                            {
                                categoryList && categoryList.length > 0 && categoryList.map((item, key) => {
                                    return (
                                        <option value={item._id} key={key}>
                                            {item.categoryName}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <span className='text-danger'>{validateError?.categoryId}</span>
                    </div>

                    <div className="mb-3">
                        <label for="withdrawamount" className="form-label">
                            Description
                        </label>
                        <textarea
                            rows={3}
                            name="message"
                            value={message}
                            onChange={handleChange}
                            placeholder="Enter Message"
                            className="form-control primary_asset_inp"
                        />
                        <div className="form-text ms-3">
                            Please enter the details of your request. A member of our
                            support staff will respond as soon as possible.
                        </div>
                        <span className='text-danger'>{validateError?.message}</span>
                    </div>

                    <div className="mb-3">
                        <label for="withdrawamount" className="form-label">
                            Upload Document (Optional)
                        </label>
                        <div className="custom_file_upload btn">
                            Upload
                            <input type="file" name="image" onChange={handleFileChange} />
                        </div>
                        <div className="form-text ms-1">
                            Max. upload file size 1MB (Jpeg, Png, Pdf ,Jpg only).
                        </div>
                        <span className='text-danger'>{validateError?.image}</span>
                    </div>

                    {image?.name &&
                        <div className="uploaded_files_row">
                            <div>
                                <i className="bi bi-file-earmark-plus"></i>
                                <span>{image?.name}</span>
                            </div>
                            <div>
                                <i className="bi bi-x-circle" onClick={() => setFormValue({ ...formValue, ...{ ['image']: "" } })}></i>
                            </div>
                        </div>
                    }

                    <div className="asset_modal_btn_grp mt-5">
                        <button
                            className="primary_btn "
                            type="button"
                            onClick={handleFormSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CreateTicket;