import React, { useState } from 'react';

//import api
import { closeTicket, replyMessage } from '../../api/support';

//import lib
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
    'message': '',
    'supportimage': '',
}
let imageType = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'pdf', 'PDF']
const TicketReply = (props) => {

    //props
    const { ticketId, receiverId, replyChatFun, closeTicketFun } = props;

    //state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const { message, supportimage } = formValue;
    const [loader, setLoader] = useState(false);

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
    }


    const handleFile = async (e) => {
        const { name, files } = e.target;
        if (!imageType.includes(files[0].type.split('/')[1])) {
            return toastAlert("error", 'Image can only be in JPEG / PNG /Jpg  formats', "updateProfile");

        }
        if (files[0]?.size >= 1000000) {
            return toastAlert("error", "Upload image cannot more then 1MB!", "updateProfile");
        }
        let formData = { ...formValue, ...{ [name]: files[0] } }

        setFormValue(formData)
    }



    const handleSubmit = async () => {
        setLoader(true);
        const formData = new FormData();
        formData.append('receiverId', receiverId);
        formData.append('ticketId', ticketId);
        if (message)
            formData.append('message', message);
        if (supportimage)
            formData.append('image', supportimage);
        try {
            const { status, message, loading, error, result } = await replyMessage(formData);
            setLoader(loading);
            if (status == 'success') {
                setFormValue(initialFormValue)
                toastAlert("success", message)
                replyChatFun(result)
                setValidateError({})
            } else if (error) {
                setValidateError(error)
            }
        } catch (err) {
            console.log(err, 'error')
        }
    }

    const handleCloseTicket = async () => {
        let reqData = {
            'ticketId': ticketId
        }
        try {
            const { status, loading, error, message, result } = await closeTicket(reqData);
            if (status == 'success') {
                toastAlert('success', message, 'supportTicket');
                closeTicketFun(result.status)
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000)
            }
        } catch (err) {
            console.log(err, 'error')
        }
    }


    return (
        <>
            <div className="chat_reply">
                <input
                    type="text"
                    value={message}
                    onChange={handleChange}
                    name='message'
                    className="form-control primary_asset_inp"
                />
                <span className='text-danger'>{validateError?.message}</span>

                <i className="bi bi-send-fill" onClick={handleSubmit}></i>
            </div>
            <div className="custom_file_upload btn mt-3">
                Upload
                <input type="file" name="supportimage" onChange={handleFile} />
            </div>
            <span className='text-danger'>{validateError?.image}</span>
            <div className="form-text ms-1">
                Max. upload file size 1MB (Jpeg, Png, Pdf , Jpg only).
            </div>
            {supportimage?.name &&
                <div className="uploaded_files_row">
                    <div>
                        <i className="bi bi-file-earmark-plus"></i>
                        <span>{supportimage?.name}</span>
                    </div>
                    <div>
                        <i className="bi bi-x-circle" onClick={() => setFormValue({ ...formValue, ...{ ['supportimage']: "" } })}></i>
                    </div>
                </div>
            }
            <div className="asset_modal_btn_grp mt-4">
                <button
                    className="primary_btn primary_btn_with_bg primary_btn_transform support-close"
                    type="button"
                    onClick={handleCloseTicket}
                >
                    Close Ticket
                </button>
            </div>

        </>
    )
}


export default TicketReply;