import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import NavbarFixed from "../components/Navbar-Fixed.js";
import { Link, NavLink } from "react-router-dom";

// components
import LoginForm from '../components/Login';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Login(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="innerpages_wrapper">
        <div className="right_blue left_orange">
        <div className="auth_wrapper">
          <LoginForm />
          </div>
        </div>
      </div>
      <Footer />
      {/* <NavbarFixed /> */}
    </div>
  );
}
